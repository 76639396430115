import { TextField } from "@mui/material";
import { useState, useEffect } from "react";

export function FrameworkHours({ f, frameworksHours, setFrameworksHours }) {
    const [maxHours, setMaxHours] = useState(frameworksHours[f.id] || '');

    useEffect(() => {
        setMaxHours(frameworksHours[f.id] || '');
    }, [frameworksHours, f.id]);

    return (
        <div key={f.id} style={{ paddingBottom: '10px', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <TextField
                style={{ width: '45%' }}
                label={f.name}
                value={maxHours}
                type="number"
                required={true}
                variant="filled"
                onChange={(e) => {
                    const newValue = e.target.value;
                    setMaxHours(newValue);
                    setFrameworksHours(prevState => ({
                        ...prevState,
                        [f.id]: newValue // Use newValue instead of maxHours
                    }));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
}