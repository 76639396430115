// src/chooseKollel.jsx
import React from 'react';
import { useNavigate } from "react-router";
import logoBH from './img/logoBH.jpg';
import logoPK from './img/logoPK.jpg';
import './ChooseKollel.css'; // Import the CSS file
import CustomToolbar from './toolBar/toolBar';
import {setFavicon} from "./favicon";

export const ChooseKollel = () => {
    const kollels = [
        { key: 'BH', logo: logoBH },
        { key: 'PK', logo: logoPK }
    ];
    const navigator = useNavigate();
    const setCustomer = (kollel) => {
        localStorage.setItem('kollel', kollel)
        setFavicon(kollel);
        navigator('/login');
    };
    localStorage.removeItem('token')
    return (
        <>
            <CustomToolbar />
            <div className="kollel-page">
                <h1 className="kollel-title">בחר רשת</h1>
                <div className="kollel-container">
                    {kollels.map(kollel => (
                        <div key={kollel.key} className="kollel-item" onClick={() => setCustomer(kollel.key)}>
                            <img src={kollel.logo} alt={`${kollel.key} logo`} className="kollel-logo" />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};