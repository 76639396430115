import {Alert, AlertTitle, Box, Collapse} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export function ErrorMassage({error, open, setOpen}) {
    return (
        <Box sx={{width: '100%', display:'flex', justifyContent:'space-between'}}>
            <Collapse in={open}>
                <Alert
                    severity="error"
                    style={{marginBottom: '10px', marginTop: '20px', width: '100%', fontSize:'inherit'}}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(null);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{mb: 2}}
                >
                    <AlertTitle style={{paddingRight: '5px', fontSize:'large', fontWeight:'bold'}}>שגיאה</AlertTitle>
                    {error}
                </Alert>
            </Collapse>

        </Box>
    );
}