import { Button, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useEffect } from 'react';
import useFetchWithAuth from "./hooks/useFetchWithAuth";

const FrameworkForm = ({ isEditing, framework, close, setRowData, rowData }) => {
  const fetchWithAuth = useFetchWithAuth();


  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: isEditing ? {
      ...framework,
    } : {}
  });

  useEffect(() => {
    // Set initial value of consecutiveSize based on the value of isConsecutive
    setValue('consecutiveSize', watch('isConsecutive') ? '' : null);
  }, [watch("isConsecutive")]);


  const onSubmit = async (data) => {
    data.nameEn=data.nameHe.replaceAll(" ","-")
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/frameworks/${isEditing ? 'update' : 'new'}`, {
        method: isEditing ? 'PUT' : 'POST',
        body: JSON.stringify(isEditing ? { ...data, id: framework.id } : data)
      })
      if (response.status === 200) {
        let data = await response.json()
        data.name = `${data.nameHe}`

        if (isEditing) {
          const _index = rowData.findIndex(d => d.id === data.id)
          let newData = rowData
          newData.splice(_index, 1, data)

          setRowData([...newData.map((row) => {
            return { ...row, name: `${row.nameHe}` }
          })])
        }
        else {
          setRowData(prev => ([...prev, data]))
        }

      }
    }
    catch {
      console.error("error on submit")
    }

    close()
  };

  return (

    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
      <h3 style={{ width: "100%", textAlign: "center", marginTop: 0 }}>{isEditing ? "עריכת מסגרת" : "הוספת מסגרת"}</h3>
      <div className='input-lable'>
        <label htmlFor="nameHe" style={{ textAlign: 'right' }}>שם מסגרת</label>
        <TextField
          id="nameHe"
          {...register('nameHe', { required: true })}
          inputProps={{ style: { textAlign: 'right', padding: "2px" } }}
          style={{ width: '150px' }}
        />

      </div>
      {/* <div className='input-lable'>
        <label htmlFor="nameEn" style={{ textAlign: 'right' }}>שם מסגרת באנגלית</label>
        <TextField
          id="nameEn"
          {...register('nameEn', { required: true })}
          inputProps={{ style: { textAlign: 'right', padding: "2px" } }}
          style={{ width: '150px' }}
        />

      </div> */}
      <div className='input-lable'>
        <label htmlFor="message" style={{ textAlign: 'right' }}>הודעות גבאי</label>
        <TextField
          id="message"
          multiline
          rows={4}
          {...register('message')}
          inputProps={{ style: { textAlign: 'right' } }}
          style={{ width: '200px' }}
        />

      </div>
      <div className='input-lable'>
        <label htmlFor="isConsecutive" style={{ textAlign: 'right' }}> רצופות</label>

        {/* <Checkbox style={{ margin: 0 }}  {...register("isConsecutive",{defaultChecked:true})} /> */}
        <input type='checkbox' {...register("isConsecutive")}></input>


      </div>
      {<div className='input-lable'>

        <label htmlFor="consecutiveSize" style={{ textAlign: 'right' }}>כמות שעות לחישוב רצופות</label>
        <TextField
          id="consecutiveSize"
          type="number"
          {...register('consecutiveSize', { required: watch("isConsecutive") })}
          inputProps={{
            style: { textAlign: 'right', padding: "2px" },
            min: 0,
            max: 100,
            step: 1,
          }}
          style={{ width: '50px' }}
          disabled={!watch("isConsecutive")}
        />

      </div>}
      <Button type="submit" variant="contained" style={{ marginBottom: "5px" }}>
        {isEditing ? "עדכן" : "הוסף"}
      </Button>
    </form>
  );
};

export default FrameworkForm;
