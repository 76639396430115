import React, { useEffect, useState, useRef } from 'react';
import { Box, ListItemText, Checkbox, ListItemButton, ListItemIcon, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const CustomYearMonthFilter = ({ onFilterChanged, fieldName, filters = { years: [], months: [] }, filterName, clearFilters, width }) => {
  const [filterValue, setFilterValue] = useState({ year: [], month: [] });
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e, filterType, value) => {
    const updatedFilterValue = {
        ...filterValue,
        [filterType]: filterValue[filterType].includes(value)
            ? filterValue[filterType].filter(item => item !== value)
            : [...filterValue[filterType], value]
    };
    setFilterValue(updatedFilterValue);
    onFilterChanged(fieldName, updatedFilterValue, clearFilters);
};

  useEffect(() => {
    if (clearFilters) {
      setOpen(false);
      setFilterValue({ year: [], month: [] });
    }
  }, [clearFilters]);

  return (
    <div ref={dropdownRef}>
      <ListItemButton onClick={handleClick} style={{ backgroundColor: "#bbb4b4", marginRight: "5px", marginLeft: "5px", maxHeight: "30px", borderRadius: "3px", width: width ? width : "500px" }} >
        <ListItemText primary={filterName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ position: "absolute", marginLeft: "4px", width: width ? width : "800px" }}>
        <Box style={{ backgroundColor: "#ffffff", border: "1px solid #ccc", borderRadius: "4px", padding: "10px", boxSizing: "border-box", display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '20px', flex: '1 1 50%' }}>
            <ListItemText primary="שנה" style={{ paddingLeft: "16px", paddingTop: "8px" }} />
            {filters.years.map(year => (
              <ListItemButton key={year} onClick={(e) => handleChange(e, 'year', year)} sx={{ pl: 4 }} style={{ backgroundColor: "#f9f9f9", height: "40px" }}>
                <ListItemIcon>
                  <Checkbox onChange={() => {}} checked={filterValue.year.includes(year)} name={year} />
                </ListItemIcon>
                <ListItemText primary={year} />
              </ListItemButton>
            ))}
          </div>
          <div style={{ flex: '1 1 50%' }}>
            <ListItemText primary="חודש" style={{ paddingLeft: "16px", paddingTop: "8px" }} />
            {filters.months.map(month => (
              <ListItemButton key={month} onClick={(e) => handleChange(e, 'month', month)} sx={{ pl: 4 }} style={{ backgroundColor: "#f9f9f9", height: "40px" }}>
                <ListItemIcon>
                  <Checkbox onChange={() => {}} checked={filterValue.month.includes(month)} name={month} />
                </ListItemIcon>
                <ListItemText primary={month} />
              </ListItemButton>
            ))}
          </div>
        </Box>
      </Collapse>
    </div>
  );
};

export default CustomYearMonthFilter;