export function convertToHebrewLetters(year) {
    const hebrewDictionary = {
        "1": "א",
        "2": "ב",
        "3": "ג",
        "4": "ד",
        "5": "ה",
        "6": "ו",
        "7": "ז",
        "8": "ח",
        "9": "ט",
        "10": "י",
        "20": "כ",
        "30": "ל",
        "40": "מ",
        "50": "נ",
        "60": "ס",
        "70": "ע",
        "80": "פ",
        "90": "צ",
        "100": "ק",
        "200": "ר",
        "300": "ש",
        "400": "ת",
        "500": "תק",
        "600": "תר",
        "700": "תש",
        "800": "תת",
        "900": "תתק",
        "1000": "א",
        "2000": "ב",
        "3000": "ג",
        "4000": "ד",
        "5000": "ה",
        "6000": "ו"
    };

    const thousands = Math.floor(year / 1000) * 1000;
    const hundreds = Math.floor((year % 1000) / 100) * 100;
    const tens = Math.floor((year % 100) / 10) * 10;
    const ones = year % 10;

    return [
        hebrewDictionary[thousands],
        hebrewDictionary[hundreds],
        hebrewDictionary[tens],
        hebrewDictionary[ones]
    ].join("");
}

export function convertToGregorianYear(hebrewYear) {
    const hebrewToNumberMap = {
        'א': 1, 'ב': 2, 'ג': 3, 'ד': 4, 'ה': 5,
        'ו': 6, 'ז': 7, 'ח': 8, 'ט': 9, 'י': 10,
        'כ': 20, 'ל': 30, 'מ': 40, 'נ': 50,
        'ס': 60, 'ע': 70, 'פ': 80, 'צ': 90,
        'ק': 100, 'ר': 200, 'ש': 300, 'ת': 400,
        'ך': 20, 'ם': 40, 'ן': 50, 'ף': 80, 'ץ': 90,
        'תק': 500, 'תר': 600, 'תש': 700, 'תת': 800, 'תתק': 900
    };

    // Initialize the numeric value of the Hebrew year
    let numericValue = 0;

    // Check if the first character is 'ה'
    if (hebrewYear[0] === 'ה') {
        // Remove the first character (drop 'ה') for the computation
        hebrewYear = hebrewYear.substring(1);
    }

    // Iterate over each character in the Hebrew year string
    for (let char of hebrewYear) {
        if (hebrewToNumberMap[char] !== undefined) {
            numericValue += hebrewToNumberMap[char];
        } else {
            console.warn(`Unrecognized character '${char}' in Hebrew year string.`);
        }
    }

    // Hebrew years commonly omit the millennium (thousands digit), so we add 5000
    return numericValue + 5000;
}

export const monthes = ["תשרי", "חשון", "כסלו", "טבת", "שבט", "אדר", "אדר א", "אדר ב", "ניסן", "אייר", "סיון", "תמוז", "אב", "אלול"]

export const hebrewComparator = (valueA, valueB) => {
    return valueA.localeCompare(valueB, 'he');
};
