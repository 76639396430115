import React from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from "react-router";
import logoBH from '../img/logoBH.jpg';
import logoPK from '../img/logoPK.jpg';

const LogoButton = () => {
    const navigate = useNavigate();

    const handleNewButtonClick = () => {
        localStorage.removeItem("kollel");
        navigate("/");
    };

    const kollel = localStorage.getItem("kollel");
    let logoSrc;
    let buttonStyle = {};

    if (kollel === "BH") {
        logoSrc = logoBH;
    } else if (kollel === "PK") {
        logoSrc = logoPK;
    } else {
        logoSrc = null;
        buttonStyle = { backgroundColor: 'gray' };
    }

    return (
        <Button
            variant="contained"
            onClick={handleNewButtonClick}
            color='inherit'
            sx={{ ml: 2, borderRadius: '50%', minWidth: '40px', minHeight: '40px', width: '40px', height: '40px', padding: 0, ...buttonStyle }}
        >
            {logoSrc ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                    <img src={logoSrc} alt="logo" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                </Box>
            ) : null}
        </Button>
    );
};

export default LogoButton;