import { useForm } from "react-hook-form"
import { Button } from "@mui/material"
import useFetchWithAuth from "../hooks/useFetchWithAuth";
const EditStudentPermissions = ({ student, frameworks, setRowData, close }) => {

    const { register, handleSubmit } = useForm()
    const fetchWithAuth = useFetchWithAuth();


    const onSubmit = async (data) => {
        const newPermissions = Object.keys(data).filter(key => data[key])
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/permissions/studentPermissions`,
                {
                    method: "POST",
                    body: JSON.stringify({ tz: student.studentTz, permissions: newPermissions })
                })
            if (response.ok) {
                const newStudent = { ...student, allowedFrameworks: newPermissions.map(p => frameworks.find(f => f.id == p).nameHe) }
                setRowData(prev => {
                    const _index = prev.findIndex(item => item.studentTz === student.studentTz)
                    let newData = [...prev]
                    newData.splice(_index, 1, newStudent)
                    return newData
                })
                close()
            }
        }
        catch {
            console.error("error on update permissions")
        }
    }

    return <div style={{ direction: "rtl" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1 style={{ textAlign: "center" }}>עריכת הרשאות</h1>
            <h5 style={{ textAlign: "center" }}>{student.studentTz}/{student.name}</h5>
            {frameworks.map(f => {
                return <p key={f.id}>

                    <input type="checkbox" {...register(`${f.id}`)} defaultChecked={student.allowedFrameworks.includes(f.nameHe)} />
                    {f.nameHe}
                </p>
            })}
            <Button type="submit" variant="contained" style={{ marginRight:"35%" }}>
               עדכן
            </Button>
        </form>
    </div>
}
export default EditStudentPermissions